import React, { useState, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { marked } from 'marked';

// DERMGURU SPECIFIC CONFIG

// Setup marked options once
marked.setOptions({
  gfm: true, // GitHub Flavored Markdown
  breaks: true, // Convert \n to <br>
  silent: true, // Do not throw on error
});

const defaultConversationStarterMessage =
  "Hey there, I'm DermGuru's AI. Chat with me about your skin care needs.";

const DERMGURU_IMAGE_AVATAR =
  'https://res.cloudinary.com/fetch-ai/image/upload/v1731590887/flockx-community-app/static-assets/common/penny-avatar.jpg';

const Config = {
  sessionId: uuidv4(),
  websocketUrl: process.env.GATSBY_WIDGET_WEBSOCKET_URL,
  twinId:
    process.env.GATSBY_AGENT_DERMGURU || '6c39f321-ef81-4a6a-ad67-c439c2ada5ff',
  aiAvatarImage: DERMGURU_IMAGE_AVATAR,
  defaultMessage: defaultConversationStarterMessage,
  widgetTitle: "Chat with DermGuru's AI",
  reconnectAttempts: 5,
  reconnectBaseTimeout: 1000,
  mobile: {
    breakpoint: 768,
  },
};

class WebSocketService {
  constructor(socketUrl) {
    this.socketUrl = socketUrl;
    this.ws = null;
    this.reconnectAttempts = 0;
    this.maxReconnectAttempts = Config.reconnectAttempts;
    this.reconnectTimeout = Config.reconnectBaseTimeout;
    this.messageHandler = null;
    this.connectionStatus = 'disconnected';
    this.heartbeatInterval = null;
    this.lastHeartbeatResponse = Date.now();
    this.heartbeatTimeout = null;
    this.hasInitiallyConnected = false;
    this.connectPromise = null;
    this.connectResolve = null;
    this.connectReject = null;
  }

  connect(sessionId) {
    if (this.ws?.readyState === WebSocket.OPEN) return Promise.resolve();

    // Clear any existing connection
    this.disconnect();

    this.connectionStatus = 'connecting';
    const url = `${this.socketUrl}${sessionId}`;

    this.connectPromise = new Promise((resolve, reject) => {
      this.connectResolve = resolve;
      this.connectReject = reject;

      try {
        this.ws = new WebSocket(url);
        this.setupWebSocketHandlers(sessionId);

        const timeout = setTimeout(() => {
          if (this.connectionStatus === 'connecting') {
            console.error('⏰ WebSocket connection timeout');
            this.handleClose(sessionId);
            reject(new Error('Connection timeout'));
          }
        }, 10000);

        this.ws.onopen = () => {
          clearTimeout(timeout);
          this.connectionStatus = 'connected';
          this.hasInitiallyConnected = true;
          this.resetReconnectParams();
          this.startHeartbeat();
          resolve();
        };
      } catch (error) {
        console.error('Failed to create WebSocket:', error);
        reject(error);
        this.handleReconnect(sessionId);
      }
    });

    return this.connectPromise;
  }

  setupWebSocketHandlers(sessionId) {
    if (!this.ws) return;

    this.ws.onmessage = this.handleMessage.bind(this);
    this.ws.onerror = this.handleError.bind(this);
    this.ws.onclose = () => this.handleClose(sessionId);
  }

  handleMessage(event) {
    try {
      const data = JSON.parse(event.data);

      // Update last heartbeat response time
      if (data.event === 'heartbeat') {
        this.lastHeartbeatResponse = Date.now();
        return;
      }

      if (this.messageHandler) {
        this.messageHandler(event);
      }
    } catch (error) {
      console.error('Error handling message:', error);
    }
  }

  handleError(error) {
    console.error('🔴 WebSocket error:', error);
    this.connectionStatus = 'error';
    if (this.connectReject) {
      this.connectReject(error);
    }
  }

  handleClose(sessionId) {
    const wasConnected = this.connectionStatus === 'connected';
    this.connectionStatus = 'disconnected';
    this.clearHeartbeat();

    if (wasConnected || this.hasInitiallyConnected) {
      this.handleReconnect(sessionId);
    } else if (this.connectReject) {
      this.connectReject(new Error('Connection closed before established'));
    }
  }

  startHeartbeat() {
    this.clearHeartbeat();

    // Send heartbeat every 30 seconds
    this.heartbeatInterval = setInterval(() => {
      if (this.ws?.readyState === WebSocket.OPEN) {
        this.ws.send(JSON.stringify({ event: 'heartbeat' }));

        // Check if we received a response to our last heartbeat
        this.heartbeatTimeout = setTimeout(() => {
          const timeSinceLastHeartbeat =
            Date.now() - this.lastHeartbeatResponse;
          if (timeSinceLastHeartbeat > 45000) {
            // 45 seconds
            console.error('❌ No heartbeat response received');
            this.ws?.close();
          }
        }, 45000);
      }
    }, 30000);
  }

  clearHeartbeat() {
    if (this.heartbeatInterval) {
      clearInterval(this.heartbeatInterval);
      this.heartbeatInterval = null;
    }
    if (this.heartbeatTimeout) {
      clearTimeout(this.heartbeatTimeout);
      this.heartbeatTimeout = null;
    }
  }

  resetReconnectParams() {
    this.reconnectAttempts = 0;
    this.reconnectTimeout = Config.reconnectBaseTimeout;
  }

  handleReconnect(sessionId) {
    if (this.reconnectAttempts < this.maxReconnectAttempts) {
      this.reconnectAttempts += 1;
      this.reconnectTimeout *= 2; // Exponential backoff
      console.log(
        `🔄 Attempting reconnect ${this.reconnectAttempts}/${this.maxReconnectAttempts}`
      );
      setTimeout(() => {
        this.connect(sessionId)
          .then(() => {
            // Restore message handler after reconnection
            if (this.messageHandler) {
              this.setupWebSocketHandlers(sessionId);
            }
          })
          .catch((error) => {
            console.error('Reconnection failed:', error);
          });
      }, this.reconnectTimeout);
    } else {
      console.error('❌ Max reconnection attempts reached');
    }
  }

  send(message) {
    if (!this.ws || this.ws.readyState !== WebSocket.OPEN) {
      console.error('WebSocket is not connected. Cannot send message.');
      return false;
    }

    try {
      this.ws.send(JSON.stringify(message));
      return true;
    } catch (error) {
      console.error('Error sending message:', error);
      return false;
    }
  }

  disconnect() {
    this.clearHeartbeat();
    if (this.ws) {
      this.ws.close();
      this.ws = null;
    }
    this.connectionStatus = 'disconnected';
    this.hasInitiallyConnected = false;
  }

  setMessageHandler(handler) {
    this.messageHandler = handler;
  }

  isConnected() {
    return this.ws?.readyState === WebSocket.OPEN;
  }
}

const getCurrentTime = () =>
  new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  }).format(new Date());

const getCurrentTimestamp = () => new Date().toISOString();

// Function to sanitize HTML before rendering it with dangerouslySetInnerHTML
const sanitizeAndFormatHtml = (html) => {
  // First pass to replace content
  let result = html
    // Apply spacing fixes directly to HTML elements
    .replace(
      /<ul>/g,
      '<ul style="margin:0.5rem 0;padding-left:0.5rem;list-style-position:outside;list-style-type:disc;">'
    )
    .replace(
      /<ol>/g,
      '<ol style="margin:0.5rem 0;padding-left:0.5rem;list-style-position:outside;">'
    )
    .replace(
      /<li>/g,
      '<li style="margin:0;padding:0;line-height:1.3;margin-bottom:0.75rem;">'
    )
    .replace(
      /<p>/g,
      '<p style="margin:0 0 0.25rem 0;padding:0;line-height:1.3;">'
    )

    .replace(
      /<a /g,
      '<a style="color:#4f46e5;text-decoration:underline;font-weight:500;" target="_blank" rel="noopener noreferrer" '
    )

    // Fix spacing between paragraphs and lists with more space
    .replace(
      /<p style="[^"]*">(.+?)<\/p>\s*<ul/g,
      '<p style="margin:0 0 0.5rem 0;padding:0;line-height:1.3;">$1</p><ul'
    )
    .replace(
      /<p style="[^"]*">(.+?)<\/p>\s*<ol/g,
      '<p style="margin:0 0 0.5rem 0;padding:0;line-height:1.3;">$1</p><ol'
    )

    // Remove paragraphs in list items completely - this ensures no wrapping
    .replace(
      /<li style="[^"]*"><p style="[^"]*">(.+?)<\/p><\/li>/g,
      '<li style="margin:0;padding:0;line-height:1.3;margin-bottom:0.75rem;">$1</li>'
    )

    // Handle nested paragraphs in list items (non-first paragraph)
    .replace(
      /<li style="[^"]*">(.*?)<p style="[^"]*">(.+?)<\/p>(.*?)<\/li>/g,
      '<li style="margin:0;padding:0;line-height:1.3;margin-bottom:0.75rem;">$1<br>$2$3</li>'
    )

    // Add blue background for code elements
    .replace(
      /<code>/g,
      '<code style="background-color:rgba(59, 130, 246, 0.1);padding:0.1rem 0.2rem;border-radius:0.2rem;font-family:monospace;">'
    )

    // Remove any empty paragraphs
    .replace(/<p style="[^"]*"><\/p>/g, '')

    // Remove any whitespace between elements that might cause extra spacing
    .replace(/>\s+</g, '><');

  // Second pass to remove margins on final elements
  result = result
    // Remove margin on the last paragraph
    .replace(
      /<p style="[^"]*">(.*?)<\/p>$/s,
      '<p style="margin:0;padding:0;line-height:1.3;">$1</p>'
    )

    // Make sure the last list has no bottom margin
    .replace(
      /<(ul|ol) style="[^"]*">(.*?)<\/(ul|ol)>$/s,
      '<$1 style="margin:0.5rem 0 0 0;padding-left:0.5rem;list-style-position:outside;">$2</$3>'
    )

    // Remove bottom margin from last list item in each list
    .replace(
      /<li style="[^"]*">(.*?)<\/li>(?=<\/(ul|ol)>)/gs,
      '<li style="margin:0;padding:0;line-height:1.3;">$1</li>'
    )

    // Remove any trailing whitespace or empty paragraphs at the end
    .replace(/\s*<\/div>$/, '</div>');

  return result;
};

function Message({ message, isStreaming }) {
  const isHuman = message.role_type === 'human';

  // More robust preprocessing to clean up the text
  const processedMessage = React.useMemo(() => {
    if (!message.message) return '';

    // First do basic cleanup
    let processed = message.message
      // Normalize line endings
      .replace(/\r\n/g, '\n')
      // Fix list items with extra space after the marker
      .replace(/^([\*\-]) +/gm, '$1 ')
      .replace(/^(\d+\.) +/gm, '$1 ')
      // Remove 3+ newlines (excessive breaks)
      .replace(/\n{3,}/g, '\n\n')
      // Make sure there's one blank line before lists (except at start)
      .replace(/([^\n])\n([*\-] )/g, '$1\n\n$2')
      .replace(/([^\n])\n(\d+\. )/g, '$1\n\n$2')
      // Make sure there's not too much space before and after list items
      .replace(/\n\n\n([\*\-] )/g, '\n\n$1')
      .replace(/\n\n\n(\d+\. )/g, '\n\n$1')
      // Make sure there's a break after lists if followed by non-list content
      .replace(/([\*\-] .*)\n([^\s*\-\d])/g, '$1\n\n$2')
      .replace(/(\d+\. .*)\n([^\s*\-\d])/g, '$1\n\n$2')
      // Trim any trailing spaces at the end of lines
      .replace(/ +$/gm, '')
      // Trim excess whitespace at the end of the message
      .replace(/\n+$/, '')
      // Trim whitespace
      .trim();

    // Make sure the message doesn't end with a newline
    return processed;
  }, [message.message]);

  // Use marked to render HTML directly
  const htmlContent = React.useMemo(() => {
    // Add one space at the end to ensure proper rendering
    return sanitizeAndFormatHtml(marked.parse(processedMessage));
  }, [processedMessage]);

  return (
    <div className="mb-6">
      <div
        className={`flex items-end gap-2 ${
          isHuman ? 'flex-row-reverse' : 'flex-row'
        }`}
      >
        <div className="flex-shrink-0 mt-1">
          <div
            className={`w-8 h-8 rounded-xl flex justify-center items-center shadow-lg transform transition-all duration-300 hover:scale-110 ${
              isHuman
                ? 'bg-gradient-to-br from-[#ea562d] to-[#f97026]'
                : 'bg-gradient-to-br from-blue-600 to-cyan-500'
            }`}
          >
            {!isHuman ? (
              <img
                src={Config.aiAvatarImage}
                alt="AI"
                className="w-8 h-8 rounded-xl object-cover"
              />
            ) : (
              <svg width="16" height="16" viewBox="0 0 24 24">
                <path
                  fill="white"
                  d="M12 4a4 4 0 100 8 4 4 0 000-8zM6 8a6 6 0 1112 0A6 6 0 016 8zm2 10a3 3 0 00-3 3 1 1 0 11-2 0 5 5 0 015-5h8a5 5 0 015 5 1 1 0 11-2 0 3 3 0 00-3-3H8z"
                />
              </svg>
            )}
          </div>
        </div>
        <div
          className={`inline-block max-w-[85%] px-6 py-4 rounded-2xl shadow-md backdrop-blur-sm transition-all duration-300 hover:shadow-lg ${
            isHuman
              ? 'bg-gradient-to-br from-[#ea562d] to-[#f97026] text-white'
              : 'bg-white/90 text-gray-800'
          } ${isHuman ? 'rounded-br-sm' : 'rounded-bl-sm'} break-words`}
        >
          <div className="flex items-center gap-3 mb-2">
            <span
              className={`font-mono text-xs ${
                isHuman ? 'text-white/80' : 'text-gray-500'
              } opacity-90`}
            >
              {message.displayTime || getCurrentTime()}
            </span>
          </div>
          <div className="text-[0.9375rem] leading-relaxed text-left">
            <div
              className="compact-markdown"
              style={{ margin: 0, padding: 0 }}
              dangerouslySetInnerHTML={{ __html: htmlContent }}
            />
            {isStreaming && (
              <span
                className={`ml-2 inline-block w-1.5 h-5 ${
                  isHuman ? 'bg-white' : 'bg-[#ea562d]'
                } animate-pulse rounded-full`}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function DermguruWidget({ config }) {
  const [messages, setMessages] = useState([]);
  const [currentStreamingMessage, setCurrentStreamingMessage] = useState(null);
  const [error, setError] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [isAITyping, setIsAITyping] = useState(false);

  const wsRef = useRef(null);
  const messagesEndRef = useRef(null);

  const handleNewMessage = (newMessage) => {
    if (newMessage.role_type === 'human') {
      return;
    }
    if (newMessage.type === 'message') {
      setIsAITyping(false);
      // Handle start of streaming message
      if (newMessage.metadata?.is_start) {
        setCurrentStreamingMessage({
          ...newMessage,
          message: newMessage.text, // Use text field instead of message
        });
      } else if (newMessage.metadata?.is_complete) {
        // Handle completion of streaming message
        setMessages((prev) => [
          ...prev,
          {
            ...newMessage,
            message: newMessage.text, // Use text field instead of message
          },
        ]);
        setCurrentStreamingMessage(null);
      } else if (newMessage.metadata?.is_streaming) {
        // Handle streaming chunks
        setCurrentStreamingMessage((prev) => ({
          ...prev,
          message: newMessage.text, // Update streaming message with latest chunk
        }));
      } else {
        // Handle non-streaming messages
        setMessages((prev) => [
          ...prev,
          {
            ...newMessage,
            message: newMessage.text,
          },
        ]);
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const message = inputValue.trim();
    if (message) {
      const messageObj = {
        id: uuidv4(),
        type: 'message',
        source: 'websocket',
        role_type: 'human',
        text: message,
        twin_id: Config.twinId,
        session_id: Config.sessionId,
        created_at: getCurrentTimestamp(),
        metadata: {},
        displayTime: getCurrentTime(), // Add displayTime here
      };

      // Add the message to the UI immediately
      setMessages((prev) => [
        ...prev,
        {
          ...messageObj,
          message: messageObj.text, // For display compatibility
        },
      ]);

      // Show AI typing indicator
      setIsAITyping(true);

      if (!wsRef.current.send(messageObj)) {
        setIsAITyping(false);
      }

      setInputValue('');
    }
  };

  const setupWebSocket = () => {
    wsRef.current.setMessageHandler((evt) => {
      try {
        const obj = JSON.parse(evt.data);

        if (obj.type === 'heartbeat') {
          return;
        }

        // Skip if no text content
        if (!obj?.text && !obj?.content) {
          return;
        }

        const newMessage = {
          id: obj.id || uuidv4(),
          type: obj.type || 'message',
          role_type: obj.role_type || 'ai',
          text: obj.text || obj.content,
          twin_id: obj.twin_id || Config.twinId,
          session_id: obj.session_id || Config.sessionId,
          metadata: obj.metadata || {},
          displayTime: getCurrentTime(),
          message: obj.text || obj.content, // For display compatibility
        };

        handleNewMessage(newMessage);
      } catch (websocketError) {
        console.error('Error handling WebSocket message:', websocketError);
        setIsAITyping(false);
      }
    });
  };

  const addWelcomeMessage = () => {
    const welcomeMessage = {
      id: uuidv4(),
      type: 'message',
      role_type: 'ai',
      text: Config.defaultMessage,
      twin_id: Config.twinId,
      session_id: Config.sessionId,
      displayTime: getCurrentTime(),
      message: Config.defaultMessage, // For display compatibility
      metadata: {},
    };
    setMessages([welcomeMessage]);
  };

  useEffect(() => {
    if (!('WebSocket' in window)) {
      setError(
        'Oops! Your browser is not supported. Please try a modern browser for the best experience. 🚀'
      );
      return;
    }

    wsRef.current = new WebSocketService(Config.websocketUrl);

    const setupConnection = async () => {
      try {
        await wsRef.current.connect(Config.sessionId);
        setupWebSocket();
        addWelcomeMessage();
      } catch (connectionError) {
        setError(
          "Oops! We couldn't connect to Koko's brain right now. Please refresh your page 🌵✨"
        );
      }
    };

    setupConnection();

    return () => {
      wsRef.current?.disconnect();
    };
  }, []);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      const messagesContainer =
        messagesEndRef.current.parentElement?.parentElement;
      if (messagesContainer) {
        messagesContainer.scrollTop = messagesContainer.scrollHeight;
      }
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages, currentStreamingMessage, isAITyping]);

  return (
    <div className="w-full h-full m-0 p-0 md:p-0">
      <div className="flex flex-col h-[80dvh] w-full bg-gradient-to-br from-[#ea562d]/10 via-[#f0813f]/10 to-[#f97026]/10 rounded-2xl shadow-2xl relative overflow-hidden backdrop-blur-xl md:rounded-2xl border border-white/20">
        <div className="flex-none p-4 border-b border-gray-100 bg-white/50 backdrop-blur-md">
          <h2 className="m-0 md:text-xl text-sm font-regular text-grey-300">
            {Config.widgetTitle}
          </h2>
          <p className="text-xs text-gray-300 mt-1 mb-0 italic">
            *This is AI-generated content - please verify important information.
          </p>
        </div>

        {error && (
          <div className="flex-none font-bold mx-4 mt-4 p-4 rounded-full bg-red-50 text-red-600 text-[0.9375rem] border border-red-100 shadow-sm">
            {error}
          </div>
        )}

        <div className="flex-1 min-h-0 p-6 overflow-y-auto bg-transparent scroll-smooth relative scrollbar-thin scrollbar-thumb-gray-300/50 scrollbar-track-transparent hover:scrollbar-thumb-gray-400/50">
          <div className="flex flex-col">
            {messages.map((msg) => (
              <Message
                key={msg.id}
                message={msg}
                isStreaming={msg.role_type === 'ai' && msg.status === 'typing'}
              />
            ))}
            {currentStreamingMessage && (
              <Message message={currentStreamingMessage} isStreaming />
            )}
            {isAITyping && !currentStreamingMessage && (
              <div className="flex items-center gap-4 mb-6">
                <div className="flex-shrink-0 mt-1">
                  <div className="w-8 h-8 rounded-xl flex justify-center items-center shadow-lg bg-gradient-to-br from-blue-600 to-cyan-500">
                    <img
                      src={Config.aiAvatarImage}
                      alt="AI"
                      className="w-8 h-8 rounded-xl object-cover"
                    />
                  </div>
                </div>
                <div className="inline-block px-6 py-4 rounded-2xl shadow-md backdrop-blur-sm bg-white/90 text-gray-800 rounded-bl-sm">
                  <div className="flex space-x-2">
                    <div
                      className="w-2 h-2 bg-gradient-to-r from-[#ea562d] to-[#f97026] rounded-full animate-[bounce_1s_ease-in-out_infinite] shadow-lg shadow-[#ea562d]/30"
                      style={{ animationDelay: '0ms' }}
                    />
                    <div
                      className="w-2 h-2 bg-gradient-to-r from-[#ea562d] to-[#f97026] rounded-full animate-[bounce_1s_ease-in-out_infinite] shadow-lg shadow-[#ea562d]/30"
                      style={{ animationDelay: '200ms' }}
                    />
                    <div
                      className="w-2 h-2 bg-gradient-to-r from-[#ea562d] to-[#f97026] rounded-full animate-[bounce_1s_ease-in-out_infinite] shadow-lg shadow-[#ea562d]/30"
                      style={{ animationDelay: '400ms' }}
                    />
                  </div>
                </div>
              </div>
            )}
            <div ref={messagesEndRef} />
          </div>
        </div>

        <form
          onSubmit={handleSubmit}
          className="flex-none p-4 border-t border-gray-100 bg-white/50 backdrop-blur-md"
        >
          <div className="flex items-center">
            <input
              type="text"
              placeholder="Ask me anything about your skin care needs..."
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              autoComplete="off"
              className="flex-1 px-6 py-4 text-base md:text-base text-sm leading-normal text-gray-800 bg-white/80 rounded-l-full rounded-r-none focus:outline-none focus:ring-0 border-0 outline-0 focus:shadow-[inset_0_0_6px_rgba(234,86,45,0.4)] focus:bg-white transition-all duration-200 shadow-sm h-[52px]"
            />
            <button
              type="submit"
              className="px-2 md:px-8 py-4 text-sm md:text-base leading-normal font-medium text-white bg-gradient-to-r from-[#ea562d] to-[#f97026] hover:from-[#f97026] hover:to-[#ea562d] focus:from-[#f97026] focus:to-[#ea562d] rounded-l-none rounded-r-full cursor-pointer transition-colors duration-300 focus:outline-none h-[52px]"
            >
              <span className="flex items-center justify-center">
                <span className="leading-none">Send</span>
                <svg
                  fill="none"
                  height="16"
                  width="16"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  id="fi_10322482"
                  className="ml-2 inline-block fill-white"
                >
                  <path
                    d="m22.1012 10.5616-19.34831-9.43824c-.1664-.08117-.34912-.12336-.53427-.12336-.67302 0-1.21862.5456-1.21862 1.21862v.03517c0 .16352.02005.32643.05971.48507l1.85597 7.42384c.05069.2028.22214.3526.42986.3757l8.15756.9064c.2829.0314.4969.2705.4969.5552s-.214.5238-.4969.5552l-8.15756.9064c-.20772.0231-.37917.1729-.42986.3757l-1.85597 7.4238c-.03966.1587-.05971.3216-.05971.4851v.0352c0 .673.5456 1.2186 1.21862 1.2186.18515 0 .36787-.0422.53427-.1234l19.34831-9.4382c.5499-.2682.8988-.8265.8988-1.4384s-.3489-1.1702-.8988-1.4384z"
                    fill="currentColor"
                  />
                </svg>
              </span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default DermguruWidget;
